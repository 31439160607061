<template>
  <div>
    <div
      v-if="posts.length > 0"
      class="site-title-area text-center shadow dark bg-fixed text-light"
      :style="`background-image: url(${posts[0].categoryThumbnail});
        background-size: cover;
        background-position: center;padding: 170px 0;`"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>{{ this.category.replace("-", " ") }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="blog-area default-padding bottom-less">
      <div class="container">
        <div class="blog-items row">
          <div
            class="col-md-4 single-item"
            v-for="(item, index) in posts"
            :key="index"
          >
            <div class="item">
              <div class="thumb">
                <router-link
                  :to="`/${item.category.replaceAll(' ', '-').toLowerCase()}/${
                    item.path
                  }`"
                >
                  <img
                    style="height=500px"
                    :src="
                      item.thumbnail ||
                      'https://www.tamandayu.com/_assets/images/uploads/about_us/1382349118_AIwwAmlyUM9fA8zzR6VW.jpg'
                    "
                    alt="Thumb"
                /></router-link>
              </div>
              <div class="info">
                <h4>
                  <router-link
                    :to="`/${item.category
                      .replaceAll(' ', '-')
                      .toLowerCase()}/${item.path}`"
                    >{{ item.title }}</router-link
                  >
                </h4>
                <router-link
                  :to="`/${item.category.replaceAll(' ', '-').toLowerCase()}/${
                    item.path
                  }`"
                  class="btn btn-theme border btn-md"
                  >Read More</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getListPost } from "../../common/datasource.js";
import constants from "../../common/constants";

export default {
  watch: {
    $route() {
      this.getPosts();
    },
  },
  data() {
    return {
      category: "",
      posts: [],
      constants: constants,
    };
  },
  async mounted() {
    await this.getPosts();
  },
  methods: {
    async getPosts() {
      this.posts = [];
      this.category = this.$route.params.category;
      let categoryId;
      if (this.category === "amenities") {
        categoryId = 4;
      } else if (this.category === "news-&-events") {
        categoryId = 1;
      } else if (this.category === "about-taman-dayu") {
        categoryId = 5;
      } else if (this.category === "the-resorts") {
        categoryId = 3;
      } else if (this.category === "latest-news") {
        categoryId = 1;
      } else if (this.category === "celebrations") {
        categoryId = 7;
      } else if (this.category === "taman-dayu-golf") {
        categoryId = 6;
      } else if (this.category === "2023-rates") {
        categoryId = 8;
      } else if (this.category === "promotions") {
        categoryId = 2;
      } else {
        categoryId = 0;
      }

      this.posts = await getListPost(categoryId);
    },
  },
};
</script>
